:root {
    --yellow: #F9D949;
}
* { -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box; }
html { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-family: sans-serif; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
body { margin:0;padding:0;font-family:'Pretendard','sans-serif'; font-size:13px; font-weight:400; word-break: keep-all; word-wrap: break-word; letter-spacing: -.025em; color: #333; line-height: 1.6;
    font-synthesis: none; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern'; direction: ltr; }
h1, h2, h3, h4, h5, h6, p, dl, dt, dd { margin: 0; font-weight: inherit; }
ul { padding: 0; margin: 0; font-style: normal; }
ul li { font-style: normal; list-style: none; }
em { font-style: normal; }
a, a:hover { text-decoration: none; color: inherit; }
pre { font-family: inherit; margin: 0; }

.lds-ripple {display: inline-block;position: relative;width: 80px;height: 80px;}
.lds-ripple div {position: absolute;border: 4px solid #fff;opacity: 1;border-radius: 50%;animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;}
.lds-ripple div:nth-child(2) {animation-delay: -0.5s;}

@keyframes lds-ripple {
    0% {top: 36px;left: 36px;width: 0;height: 0;opacity: 0;}
    4.9% {top: 36px;left: 36px;width: 0;height: 0;opacity: 0;}
    5% {top: 36px;left: 36px;width: 0;height: 0;opacity: 1;}
    100% {top: 0px;left: 0px;width: 72px;height: 72px;opacity: 0;}
}

.rc-pagination { display: flex; align-content: center; justify-content: center; gap: 20px; }
.rc-pagination li {}
.rc-pagination li a { display: flex; width: 40px; height: 40px; align-items: center; justify-content: center; border-radius: 100%; background: #DDDDDD; cursor: pointer;
    font-size: 16px; font-weight: 500; }
.rc-pagination li.rc-pagination-item-active a { background: #3B486B; color: #fff; }
.rc-pagination li.rc-pagination-disabled a { cursor: not-allowed; opacity: .4; }



@media screen and (max-width: 768px) {
    .rc-pagination { gap: 16px; }
    .rc-pagination li a { width: 30px; height: 30px; font-size: 14px; }
}


.fc .fc-view-harness { background: #fff; }
.react-calendar { z-index: 5; }
